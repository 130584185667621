<template>
  <div>
    <el-card shadow="hover" :body-style="{padding:'1rem 1rem'}" style="width: 100%;min-height: 30rem">
      <el-table v-loading="loading" :data="list">
        <el-table-column prop="sort" label="排序"></el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column prop="link" label="链接"></el-table-column>
        <el-table-column align="right">
          <template slot="header">
            <el-button @click="add" size="mini" type="primary">添加导航</el-button>
          </template>
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="edit(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="del(scope.row.id)">移除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog title="编辑导航" :visible.sync="editBox" width="40%" @close="close">
      <el-form ref="form" :model="form" label-width="80px" style="width: 90%">
        <el-form-item label="排序">
          <el-input v-model="form.sort"></el-input>
        </el-form-item>
        <el-form-item label="链接">
          <y_choose_link v-model="form.link"></y_choose_link>
        </el-form-item>
        <el-form-item label="标题">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editBox = false">取 消</el-button>
        <el-button type="primary" @click="formSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import y_choose_link from "@/components/y_choose_link";
export default {
  name: "tabbar",
  components:{
    y_choose_link
  },
  data() {
    return {
      list: [],
      form: {
        sort: 0,
        title: "",
        link: "",
      },
      editBox: false,
      loading:false,
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    del(id){
      this.$api.system.homeTabbarDel({id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    edit(row){
      this.form = {
        ...row
      }
      this.editBox = true;
    },
    add() {
      this.form = {
        sort: 0,
        title: "",
        link: "",
      }
      this.editBox = true;
    },
    close(){
      this.form = {
        sort: 0,
        title: "",
        link: "",
      }
      this.editBox = false;
    },
    formSubmit(){
      this.form.sort = parseInt(this.form.sort);
      this.$api.system.homeTabbarEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.editBox = false;
        this.load();
      })
    },
    load(){
      this.loading = true;
      this.$api.system.homeTabbarAll().then(res=>{
        this.loading = false;
        this.list = res;
      })
    }
  }
}
</script>

<style scoped>

</style>